<template>
  <div class="bg global-blue">
    <v-container>
      <v-row>
        <v-col>
          <h1 class="global-title-sm hidden-md-and-up mt-12">{{ translations.title[locale] }}</h1>
          <h1 class="global-title hidden-sm-and-down">{{ translations.title[locale] }}</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="10">
          <p class="white--text ma-4" v-html="translations.heading[locale]"></p>
           
          <p class="white--text ma-4" v-html="translations.heading2[locale]"></p>
          <p class="white--text ma-4 mt-8 mb-8" v-html="translations.link[locale]"></p>
          <p class="white--text ma-4">{{ translations.middle1[locale] }}</p>
          <p class="white--text ma-4">{{ translations.middle2[locale] }}</p>
          <p class="white--text ma-4" v-html="translations.middle3[locale]"></p>
          <p class="white--text ma-4 mt-8 mb-8" v-html="translations.serviceModel[locale]"></p>
          <p class="white--text ma-4 ">{{ translations.bottom[locale] }}</p>


       




        </v-col>
      </v-row>

    </v-container>
    <div class="bg-sm">

    </div>
  </div>
</template>

<style  scoped>
.bg-sm {
  height: 300px;
  width: 100%;
}
.bg {
  height: 100%;
}
.br {
  background: var(--v-primary-base) !important;
}
.trl-case-sm {
  font-size: 12px;
}
.back {
  background: var(--v-primary-base) !important;
  padding: 32px;
  margin: 16px;
  width: 100%;
}

h2 {
  font-weight: 400;
}
h1 {
  text-align: left;
}
.title-info {
  color: #f7941e;
}
</style>

<script>
import { PORTAL_GODIGITAL } from "../../constants/translations";

export default {
  name: "GoDigital",
  data() {
    return {
      translations: PORTAL_GODIGITAL,
    };
  },
  computed: {
    locale() {
      return this.$store.state.locale;
    },
  },
};
</script>